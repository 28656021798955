import React from "react";
import TabContent from "./TabContent";
import PongalFestival_Img_1 from "../../../../assets/images/gallery/2025/jan/pongal festival/Img  (1).jpeg"

const DestinationTabs = () => {
  const tab1Destinations = [
    {
      imgSrc: PongalFestival_Img_1,
      title: "Pongal Celebrations",
      overlayLink: "/gallery/Moments2025/pongalfestival",
      heading:"pongal Festival"
    },
    {
      imgSrc: "https://png.pngtree.com/thumb_back/fw800/background/20231007/pngtree-d-rendering-of-stunning-wall-decorations-for-art-gallery-or-studio-image_13572087.png",
      title: "Las Vegas",
      overlayLink: "#",
      heading:"Food Festival"
    },
  ];

  const tab2Destinations = [
    {
      imgSrc: "https://png.pngtree.com/thumb_back/fw800/background/20231007/pngtree-d-rendering-of-stunning-wall-decorations-for-art-gallery-or-studio-image_13572087.png",
      title: "San Francisco",
      overlayLink: "#",
    
      heading:" Festival"
    },
  ];

  return (
    <div className="container-fluid destination py-5">
      <div className="tab-class text-center">
        <div className="tab-content">
          <TabContent tabId="tab-1" destinations={tab1Destinations} />
          <TabContent tabId="tab-2" destinations={tab2Destinations} />
          <TabContent tabId="tab-1" destinations={tab1Destinations} />
          <TabContent tabId="tab-2" destinations={tab2Destinations} />

        </div>
      </div>
    </div>
  );
};

export default DestinationTabs;
