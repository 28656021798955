import React from "react";
import { Helmet } from "react-helmet-async";
import EventContainer from "../../layouts/eventcontainer/eventContainer";

import PongalCelebration2k25_Img_1 from "../../../../src/assets/images/gallery/2025/jan/pongal festival/Img  (1).jpeg";
import PongalCelebration2k25_Img_2 from "../../../../src/assets/images/gallery/2025/jan/pongal festival/Img  (2).jpeg";
import PongalCelebration2k25_Img_3 from "../../../../src/assets/images/gallery/2025/jan/pongal festival/Img  (3).jpeg";
import PongalCelebration2k25_Img_4 from "../../../../src/assets/images/gallery/2025/jan/pongal festival/Img  (4).jpeg";
import PongalCelebration2k25_Img_5 from "../../../../src/assets/images/gallery/2025/jan/pongal festival/Img  (5).jpeg";
import PongalCelebration2k25_Img_6 from "../../../../src/assets/images/gallery/2025/jan/pongal festival/Img  (6).jpeg";
import PongalCelebration2k25_Img_7 from "../../../../src/assets/images/gallery/2025/jan/pongal festival/Img  (7).jpeg";




const Moments2025 = () => {

const PongalCelebration2k25 =[
     {
        image:PongalCelebration2k25_Img_1,
        id: "pongal",
      },
      {
        image:PongalCelebration2k25_Img_2,
        id: "pongal",
      },
      {
        image:PongalCelebration2k25_Img_3,
        id: "pongal",
      },
      {
        image:PongalCelebration2k25_Img_4,
        id: "pongal",
      },
      {
        image:PongalCelebration2k25_Img_5,
        id: "pongal",
      },
      {
        image:PongalCelebration2k25_Img_6,
        id: "pongal",
      },
      {
        image:PongalCelebration2k25_Img_7,
        id: "pongal",
      },
   ];

    return (
<>
     <Helmet>
            <title> Gallery-JNCW </title>
          </Helmet>
          <div className="container">
        <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
          <h4 class="section-title bg-white text-center text-primary px-3">
            Gallery
          </h4>
          <h4 class="mb-5"> JNCW-2025 Moments </h4>
        </div>
            <EventContainer
          data={PongalCelebration2k25}
          header="Pongal Celebration-2025 "
          id="pongal"
        />
        
      
         
      




        
        
       
      

       

      </div>
</>
);
};
export default Moments2025;