import React, { Suspense, lazy } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Loading from "./components/loader/index.js";
import ContactUs from "./components/pages/contacts/index.js";
import Home from "./components/pages/home/index.js";
import Chairman from "./components/pages/aboutus/about";
import Secretary from "./components/pages/aboutus/secretary";
import ExecutiveDirector from "./components/pages/aboutus/ExecutiveDirector";
import Principal from "./components/pages/aboutus/principal.js"; 
import VisionMission from "./components/pages/aboutus/ourvision/index.js";
import AcademiCalendar from "./components/pages/courses/academic calendar/acdemicCalendar";
import Department from "./components/pages/courses/Depart/index.js";
import BaEnglish from "./components/pages/courses/Depart/English.js";
import Tamil from "./components/pages/courses/Depart/Tamil.js";
import Maths from "./components/pages/courses/Depart/Maths.js";
import Physics from "./components/pages/courses/Depart/Physics.js";
import Chemistry from "./components/pages/courses/Depart/Chemistry .js";
import BscComputer from "./components/pages/courses/Depart/BscComputer.js";
import Commerce from "./components/pages/courses/Depart/Commerce.js";
import ComputerApplication from "./components/pages/courses/Depart/computerApplication.js";
import Microbiology from "./components/pages/courses/Depart/Microbiology.js";
import BussinessAdministration from "./components/pages/courses/Depart/BussinessAdministration.js";
import BcomCa from "./components/pages/courses/Depart/Bca.js";
import Infrastrutre from "./components/pages/acadiems/Facility.js";
import University from "./components/pages/courses/universityRankers/index.js";
import Transport from "./components/pages/amities/transport.js";
import Libary from "./components/pages/amities/library.js";
import SmartClass from "./components/pages/amities/smartclass.js";
import Mess from "./components/pages/amities/mess.js";
import Hostel from "./components/pages/amities/hostel.js";
import Sports from "./components/pages/others/sports.js";
import Nss from "./components/pages/others/Nss.js";
import Moments2019 from "./components/pages/gallery/2019.js";
import Moments2020 from "./components/pages/gallery/2020.js";
import Moments2021 from "./components/pages/gallery/2021.js";
import Moments2022 from "./components/pages/gallery/2022.js";
import Moments2023 from "./components/pages/gallery/2023.js";
import Moments2024 from "./components/pages/gallery/2024.js";
import Moments2k25 from "./components/pages/gallery/2025.js";
import Moments2025 from "./components/pages/gallery/2025/2025.js";
import Pongalfestival2025 from "./components/pages/gallery/2025/Eventphoto/Pongalfestival";
import Faculty from "./components/pages/acadiems/Faculty.js";
import Laboratorys from "./components/pages/amities/Laboratorys.js";
import NewsUpdated from "./components/pages/News/index.js";
import Alumini from "./components/pages/Alumini/index.js";
import Placement from "./components/pages/Placement/Placement.js";
import PlacementReport from "./components/pages/Placement/report.js";
import Payment from "./components/pages/onlinePayment/index.js";
// import ContactUs from './components/pages/contacts/index.js';

const Routers = () => {
  const NAAC = lazy(() => import("./components/pages/NAAC/index.js"));
  const NIRF = lazy(() => import("./components/pages/NIRF/index.js"));

  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route exact path="/" element={<Home />} />
        {/* <Route exact path='/gallery' element={<Gallery/>}/> */}
        <Route exact path="/chairman" element={<Chairman />} />
        <Route exact path="/secretary" element={<Secretary />} />
        <Route exact path="/executive-director" element={<ExecutiveDirector />}/>
        <Route exact path="/principal" element={<Principal />} />
        <Route exact path="/vision&mission" element={<VisionMission />} />
        <Route exact path="/courses" element={<Department />} />
        <Route exact path="/facultydetails" element={<Faculty />} />
        <Route exact path="/academics/academic-calender" element={<AcademiCalendar />}/>
        <Route exact path="/academics/infrastrutre" element={<Infrastrutre />}/>
        <Route exact path="/laboratories" element={<Laboratorys />} />
        <Route exact path="/university-rankers" element={<University />} />
        <Route exact path="/amenities/library" element={<Libary />} />
        <Route exact path="/amenities/smart-class" element={<SmartClass />} />
        <Route exact path="/amenities/mess-canteen" element={<Mess />} />
        <Route exact path="/amenities/hostel" element={<Hostel />} />
        <Route exact path="/amenities/transport" element={<Transport />} />
        <Route exact path="/courses-baenglish" element={<BaEnglish />} />
        <Route exact path="/courses-baTamil" element={<Tamil />} />
        <Route exact path="/courses-bscmaths" element={<Maths />} />
        <Route exact path="/courses-bscphysics" element={<Physics />} />
        <Route exact path="/courses-bscchemistry" element={<Chemistry />} />
        <Route exact path="/courses-bsccomputer" element={<BscComputer />} />
        <Route exact path="/courses-bscmicrobiology" element={<Microbiology />}/>
        <Route exact path="/courses-commerce" element={<Commerce />} />
        <Route exact path="/courses-computerapplication" element={<ComputerApplication />}/>
        <Route exact path="/courses-bba" element={<BussinessAdministration />} />
        <Route exact path="/courses-b.com.ca" element={<BcomCa />} />
        <Route exact path="/news&updates" element={<NewsUpdated />} />
        <Route exact path="/alumini" element={<Alumini />} />
        <Route exact path="/placement" element={<Placement />} />
        <Route exact path="/placement-report" element={<PlacementReport />} />
        <Route exact path="/naac" element={<NAAC />} />
        <Route exact path="/nirf" element={<NIRF />} />
        <Route exact path="/gallery/Moments2025" element={<Moments2025 />} />
        <Route exact path="/gallery/Moments2025/pongalfestival" element={<Pongalfestival2025 />} />
        <Route exact path="/gallery/2025" element={<Moments2k25 />} />
        <Route exact path="/gallery/2024" element={<Moments2024 />} />
        <Route exact path="/gallery/2023" element={<Moments2023 />} />
        <Route exact path="/gallery/2022" element={<Moments2022 />} />
        <Route exact path="/gallery/2021" element={<Moments2021 />} />
        <Route exact path="/gallery/2020" element={<Moments2020 />} />
        <Route exact path="/gallery/2019" element={<Moments2019 />} />
        <Route exact path="/sports" element={<Sports />} />
        <Route exact path="/online-payment" element={<Payment />} />
        <Route exact path="/nss-yrc-rrc" element={<Nss />} />
        <Route exact path="/contactus" element={<ContactUs />} />
      </Routes>
    </Suspense>
  );
};
export default Routers;
