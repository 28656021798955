import React, { useState, useEffect } from "react";
import Modal from "./modal"; // Assuming you have a reusable Modal component
import Modalbanner from "../../assets/images/Modal image/Modal imag (1).jpeg";
import Modalbanner2 from "../../assets/images/Modal image/Modal imag (2).jpeg";
import { Link, useLocation } from "react-router-dom";
import Rightmodal from  "../../assets/images/Modal image/Modal imag (2).jpeg";// For routing functionality
import leftmodal from "../../assets/images/Modal image/Modal imag (1).jpeg";


const HomeWithImageModal = () => {
  // State management for modals
  const [isFirstModalOpen, setFirstModalOpen] = useState(false);
  const [isSecondModalOpen, setSecondModalOpen] = useState(false);
  const [isThirdModalOpen, setThirdModalOpen] = useState(false);
  const [isSideModalsOpen, setSideModalsOpen] = useState(false); // New state for side modals

  const location = useLocation(); // Get the current route's pathname

  // Open the first modal if on the Home page
  useEffect(() => {
    if (location.pathname === "/") {
      setFirstModalOpen(true);
    }
  }, [location.pathname]);

  // Handlers for closing each modal and opening the next
  const handleFirstModalClose = () => {
    setFirstModalOpen(false);
    setSecondModalOpen(true); // Open second modal
  };

  const handleSecondModalClose = () => {
    setSecondModalOpen(false);
    setThirdModalOpen(true); // Open third modal
  };

  const handleThirdModalClose = () => {
    setThirdModalOpen(false);
    setSideModalsOpen(true); // Open left and right side modals
  };

  const handleSideModalsClose = () => {
    setSideModalsOpen(false); // Close side modals
  };

  return (
    <div>
      {/* First Modal */}
      <Modal
        isOpen={isFirstModalOpen}
        onClose={handleFirstModalClose}
        style={{ color: "black", fontSize: "24px", fontWeight: "bold" }}
      >
        <h2>Admission Open 2025-2026</h2>
        <Link
          to="https://docs.google.com/forms/d/e/1FAIpQLSdd8pOpDYoSrlfvTbL-y8x7swoVqKoIZ4xTiz-hTlOmjxvTZA/viewform"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button type="button" className="btn btn-primary">
            Admission Enquiry Click Here
          </button>
        </Link>
      </Modal>

      {/* Second Modal */}
      <Modal isOpen={isSecondModalOpen} onClose={handleSecondModalClose}>
        <img
          src={Modalbanner}
          alt="Second Modal Banner"
          style={{ width: "100%", height: "auto" }}
        />
      </Modal>

      {/* Third Modal */}
      <Modal isOpen={isThirdModalOpen} onClose={handleThirdModalClose}>
        <img
          src={Modalbanner2}
          alt="Third Modal Banner"
          style={{ width: "100%", height: "auto" }}
        />
      </Modal>

         </div>
  );
};

export default HomeWithImageModal;
