import React from 'react';
import { Helmet } from 'react-helmet-async';

function Pongalfestival() {
  return (
     <>
        <Helmet>
       <title> Gallery-JNCW </title>
     </Helmet> 
   <div className="container">
   
   <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
             <h4 class="section-title bg-white text-center text-primary px-3">
               Gallery
             </h4>
             <h4 class="mb-5"> JNCW-2025 Moments </h4>
             <h4 class="mb-5"> Pongal Celebrations </h4>
           </div>
           
           </div>
     </>
  )
}

export default Pongalfestival