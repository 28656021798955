import "./style.scss";
import Logo from "../../assets/images/logo/Jncw new-png.png";

const Loader = () => {
  return (
    <div className="loader">
      <img src={Logo} alt="jncwLogo" />
    </div>
  );
};

export default Loader;