import React from "react";

const DestinationCard = ({ imgSrc, title, overlayLink, lightboxLink,heading }) => {
  return (
    <div className="col-lg-6">
    <h4 class="mb-5">{heading}</h4>
      <div className="destination-img">
        <img className="img-fluid rounded w-100" src={imgSrc} alt={title} />
        <div className="destination-overlay p-4">
          <a href="#" className="btn btn-primary text-white rounded-pill border py-2 px-3">
            20 Photos
          </a>
          <h4 className="text-white mb-2 mt-3">{title}</h4>
          <a href={overlayLink} className="btn-hover text-white">
            View All  <i className="fa fa-arrow-right ms-2"></i>
          </a>
        </div>
             </div>
      </div>
 
  );
};

export default DestinationCard;
