import React from "react";
import DestinationCard from "./DestinationCard";

const TabContent = ({ tabId, destinations }) => {
  return (
    <div id={tabId} className="tab-pane fade show p-0 active">
      <div className="row g-4">
        {destinations.map((destination, index) => (
          <DestinationCard
            key={index}
            imgSrc={destination.imgSrc}
            title={destination.title}
            overlayLink={destination.overlayLink}
            heading={destination.heading}
           
          />
        ))}
      </div>
    </div>
  );
};

export default TabContent;
